<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Import Inspection Element'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form inline @submit.stop.prevent="onSubmit">
                <b-row>
                  <b-col>
                    <label class="sr-only" for="file-import">File</label>
                    <b-form-file
                      id="file-import"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="form.file"
                      :state="validateState('file')"
                      accept=".xls, .xlsx"
                      placeholder="Pilih File..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>

                    <b-form-invalid-feedback id="file-import_feedback">
                      Tidak ada file excel terpilih.
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col>
                    <b-button type="submit" variant="outline-success" :disabled="loading">
                      <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                      <!-- <strong>Loading...</strong> -->
                      <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                        <i class="flaticon-upload"></i>
                      </span>
                      Submit
                    </b-button>

                    <b-button
                      class="ml-3"
                      variant="outline-dark"
                      :disabled="loading"
                      @click="$router.push('/master/inspectionplant')"
                    >
                      <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                      <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                        <i class="flaticon2-left-arrow-1 text-dark"></i>
                      </span>
                      Discard
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col>
              <b-alert show variant="primary" class="pl-10">
                <h3>Perhatikan :</h3>
                <ul>
                  <li>
                    Nama Equipment & Nama Part harap cek pada menu master Equipment & Part.
                    Perhatikan Besar kecil huruf.
                  </li>
                </ul>
              </b-alert>

              <b-button
                :href="base_url + 'media/template/template_import_inspectionplant.xlsx'"
                type="button"
                variant="outline-primary"
                :disabled="loading"
              >
                <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                <span v-else class="svg-icon svg-icon-sm svg-icon-primary">
                  <i class="flaticon-download"></i>
                </span>
                Download Template Excel
              </b-button>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import $ from 'jquery'

export default {
  mixins: [validationMixin],
  name: 'importisnpectionplant',
  data() {
    return {
      form: {
        file: null,
      },

      loading: false,
      base_url: null,
    }
  },
  validations: {
    form: {
      file: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.base_url = process.env.BASE_URL
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Master', route: '/master/inspectionplant' },
      { title: 'Create Inspection Element' },
    ])
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this
      self.loading = true
      const file = self.$v.form.file.$model
      const dataku = new FormData()
      dataku.append('import', file)
      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/master/inspectionplant/import',
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/master/inspectionplant')
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
